import { Logger } from "@/core/utilities";
import { useModuleSettings } from "@/core/composables/useModuleSettings";

const MODULE_ID = "Opus";
const SETTINGS_MAPPING = {
  "Opus.Analytics.DisableTrackingDomains": "disableTrackingDomains",
};

const { getModuleSettings } = useModuleSettings(MODULE_ID);

type ModuleSettingsType = { disableTrackingDomains: string };

export function getOpusPurposeLocalization(purpose: string): string {
  const purposeKey = purpose.toLowerCase().split(" ").join("_");
  return `shared.opus.quick_connect.purpose_by_key.${purposeKey}`;
}

export function formatPhoneNumber(value: string | undefined) {
  if (!value) {
    return "";
  }

  const numericValue = value.replace(/\D/g, "");

  if (numericValue.length <= 3) {
    return numericValue;
  } else if (numericValue.length <= 6) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
  } else {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
  }
}

export function validatePhoneNumber(value: string | undefined) {
  const regex = /^[0-9-]*$/;
  if (!value) {
    return "Phone number must be 10 digits.";
  } else if (!regex.test(value)) {
    return "Phone must contain only numeric values.";
  }
  return "";
}

export function isTrackingDisabled(email: string | undefined) {
  const domain = getDomainFromEmail(email);
  if (!domain) {
    return false;
  }

  let trackingDomains: string[] = [];
  const { disableTrackingDomains } = getModuleSettings(SETTINGS_MAPPING) as ModuleSettingsType;
  if (disableTrackingDomains) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const parsedStr = JSON.parse(disableTrackingDomains);
      if (Array.isArray(parsedStr)) {
        trackingDomains = parsedStr as string[];
      }
    } catch(e) {
      Logger.error(`@/core/utilities.${isTrackingDisabled.name}`, e);
    }
  }

  return trackingDomains.includes(domain);
}

function getDomainFromEmail(email?: string) {
  return email?.includes("@") ? email.split("@")[1] : null;
}
