import { computed, readonly, ref, shallowRef } from "vue";
import {
  addTaxCertificates,
  deleteTaxCertificate,
  getTaxCertificate,
  getTaxCertificates,
  updateTaxCertificate as _updateTaxCertificate,
} from "@/core/api/graphql";
import { Logger } from "@/core/utilities";
import type {
  InputTaxCertificateUploadRequestType,
  InputUpdateTaxCertificateType,
  ResultType,
  TaxCertificateType,
} from "@/core/api/graphql/types";
import type { Ref } from "vue";

const loading = ref(true);
const taxCertificateLoading = ref(false);

const certificates = shallowRef<TaxCertificateType[]>([]);
const taxCertificate: Ref<TaxCertificateType | undefined> = ref();

export function useOpDocStorage(options: { autoRefetch: boolean } = { autoRefetch: true }) {
  const keyword = ref("");

  async function createTaxCertificate(payload: InputTaxCertificateUploadRequestType[]): Promise<ResultType> {
    loading.value = true;
    taxCertificateLoading.value = true;

    try {
      return await addTaxCertificates(payload);
    } catch (e) {
      Logger.error(`${useOpDocStorage.name}.${createTaxCertificate.name}`, e);
      throw e;
    } finally {
      if (options.autoRefetch) {
        await fetchTaxCertificates();
      }
      loading.value = false;
      taxCertificateLoading.value = false;
    }
  }

  async function updateTaxCertificate(payload: InputUpdateTaxCertificateType): Promise<void> {
    taxCertificateLoading.value = true;

    try {
      await _updateTaxCertificate(payload);
    } catch (e) {
      Logger.error(`${useOpDocStorage.name}.${updateTaxCertificate.name}`, e);
      throw e;
    }

    if (options.autoRefetch && taxCertificate.value?.id) {
      await fetchTaxCertificate(taxCertificate.value?.id);
    }

    taxCertificateLoading.value = false;
  }

  async function fetchTaxCertificates(): Promise<void> {
    loading.value = true;

    try {
      certificates.value = (await getTaxCertificates({ first: 9999, keyword: keyword.value })).items || [];
    } catch (e) {
      Logger.error(`${useOpDocStorage.name}.${fetchTaxCertificates.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchTaxCertificate(taxCertificateId: string) {
    taxCertificateLoading.value = true;

    try {
      taxCertificate.value = await getTaxCertificate(taxCertificateId);
    } catch (e) {
      Logger.error(`${useOpDocStorage.name}.${fetchTaxCertificate.name}`, e);
      throw e;
    } finally {
      taxCertificateLoading.value = false;
    }
  }

  async function removeTaxCertificate(taxCertificateId: string): Promise<boolean> {
    let result = false;

    loading.value = true;

    try {
      result = (await deleteTaxCertificate(taxCertificateId)).isSuccess;
    } catch (e) {
      Logger.error(`${useOpDocStorage.name}.${removeTaxCertificate.name}`, e);
      throw e;
    }

    if (options.autoRefetch) {
      await fetchTaxCertificates();
    }

    return result;
  }

  return {
    fetchTaxCertificates,
    fetchTaxCertificate,
    createTaxCertificate,
    removeTaxCertificate,
    updateTaxCertificate,
    loading: readonly(loading),
    taxCertificateLoading: readonly(taxCertificateLoading),
    certificates: computed(() => certificates.value),
    taxCertificate: computed(() => taxCertificate.value),
    keyword,
  };
}
